import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Portal} from '@mui/material';
import React, {forwardRef, memo} from 'react';

const Modal = forwardRef(
    (
        {
            id = 'modal-dialog',
            open,
            onClose,
            title,
            description,
            children,
            actions,
            container = document.body,
            isForm = false,
            onSubmit,
            DialogTitleProps,
            DialogContentProps,
            ...props
        },
        ref,
    ) => {
        return (
            <Portal container={container}>
                <Dialog
                    ref={ref}
                    open={open}
                    onClose={onClose}
                    aria-labelledby={id + '-title'}
                    aria-describedby={description ? id + '-description' : null}
                    {...(isForm && {
                        component: 'form',
                        onSubmit: (e) => {
                            if (onSubmit) {
                                onSubmit(e);
                            }
                        },
                    })}
                    {...props}
                >
                    {title && (
                        <DialogTitle id={id + '-title'} {...DialogTitleProps}>
                            {title}
                        </DialogTitle>
                    )}
                    <DialogContent {...DialogContentProps}>
                        {description && <DialogContentText id={id + '-description'}>{description}</DialogContentText>}
                        {children}
                    </DialogContent>
                    <DialogActions>{actions}</DialogActions>
                </Dialog>
            </Portal>
        );
    },
);

export default memo(Modal);
